import { styled } from "@puzzle/theme";

export enum BurnFormula {
  TotalBurn = "total_burn",
  BankBurn = "bank_burn",
  AccrualOperatingIncomeBurn = "accrual_operating_income_burn",
  Custom = "custom",
}

export enum RevenueFormula {
  ARR = "arr",
  StripeARR = "stripe_arr",
  AccrualbasedArr = "accrual_based_arr",
}

const displayMoneyCommon = {
  color: "$gray100",
  fontWeight: "$heavy",
  fontSize: "$heading2",
  lineHeight: "$bodyXL",
  display: "flex",

  small: {
    fontSize: "$headingL",
    lineHeight: "26px",
  },
};

export const DisplayMoneyColumn = styled("div", {
  ...displayMoneyCommon,
  flexDirection: "column",
});

export const DisplayMoneySubrow = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "$1",
});

const graphContainerCommon = {
  // Graphs need to peek out of their horizontal container to fill it, while everything else does not.
  // This is the least-messy way to accomplish that.
  width: `calc("100% + 32px")`,

  marginTop: "$4",
  marginLeft: "-16px",
  marginRight: "-16px",

  transition: "filter 0.1s ease-in-out",
  defaultVariants: {
    gateBlur: false,
  },

  variants: {
    gateBlur: {
      true: {
        filter: "blur(10px)",
      },
    },
  },
};

export const GraphContainer = styled("div", {
  ...graphContainerCommon,

  height: "116px", // Adjust here
});

export const BurnTileGraphContainer = styled("div", {
  ...graphContainerCommon,

  height: "100px", // Adjust here
});
