import { useMemo } from "react";
import { DynamicReportType, LedgerReportColumnBy, ReportTimePeriod } from "graphql/types";
import { DeltaColumns } from "./types";
import { useDelta } from "./Filters/DeltaProvider";
import { useIsInProgress } from "components/reports/useReportTimePeriods";
import { useProcessReportData } from "./useProcessReportData";
import {
  BuildReportResult,
  LedgerReport,
  LedgerReportByInterval,
} from "components/dashboard/utils/fetchLedgerReport/types";

type BuildReportProps = {
  ledgerReport: LedgerReport;
  columnBy: LedgerReportColumnBy;
  timePeriods: ReportTimePeriod[];
  type: DynamicReportType;
  loading: boolean;
};

export const useBuildReport = ({
  ledgerReport,
  columnBy,
  timePeriods,
  type,
  loading,
}: BuildReportProps): BuildReportResult => {
  const { deltaOptions, firstTimePeriod, lastTimePeriod } = useDelta();
  const isInProgress = useIsInProgress(timePeriods);

  // Data processing of all nodes in the report
  const { data, parsedRootNodes, enhancedRootNodes, totalExpensesNode } = useProcessReportData(
    ledgerReport,
    lastTimePeriod,
    firstTimePeriod
  );

  // These are the columns that will be displayed in the report
  const columns = useMemo(() => {
    if (columnBy === LedgerReportColumnBy.Interval) {
      if (!deltaOptions.enabled) {
        return timePeriods.map((t) => t.timePeriodKey);
      }

      return [
        firstTimePeriod.timePeriodKey,
        lastTimePeriod.timePeriodKey,
        DeltaColumns.DollarDiff,
        DeltaColumns.PercentDiff,
        totalExpensesNode && DeltaColumns.PercentOfExpenses,
      ].filter(Boolean);
    }
    if (!ledgerReport?.lines[0]?.balanceByColumn) return [];

    const segmentCols = ledgerReport.lines[0].balanceByColumn.map(
      (col) => col.columnKey
    ) as string[];

    return segmentCols;
  }, [
    deltaOptions.enabled,
    firstTimePeriod.timePeriodKey,
    lastTimePeriod.timePeriodKey,
    timePeriods,
    totalExpensesNode,
    columnBy,
    ledgerReport?.lines,
  ]);

  const hiddenColumns = useMemo(() => {
    if (deltaOptions.enabled) {
      return [];
    }

    return [DeltaColumns.DollarDiff, DeltaColumns.PercentDiff, DeltaColumns.PercentOfExpenses];
  }, [deltaOptions.enabled]);

  const report = {
    columns,
    hiddenColumns,
    columnBy,
    type,
    data,
    rootNodes: parsedRootNodes,
    loading,
    highWatermarkToken: ledgerReport?.highWatermarkToken,
    timePeriods:
      ledgerReport && ledgerReport.__typename === "LedgerReportByInterval"
        ? (ledgerReport as LedgerReportByInterval).intervalMetadata
        : undefined,
    enhancedRootNodes,
    isInProgress,
    firstTimePeriod,
    lastTimePeriod,
  };

  return report;
};
