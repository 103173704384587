import groupBy from "lodash/groupBy";
import React, { useMemo } from "react";
import Link from "next/link";
import Big from "big.js";

import { ScrollArea, Tooltip } from "@puzzle/ui";
import { styled } from "@puzzle/theme";
import { Text as TextVE } from "ve";
import { Box } from "@puzzle/ve";

import { S } from "@puzzle/theme";
import { formatMoney, formatAccountName, Money } from "@puzzle/utils";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import { Link as LinkCommon } from "components/common/Link";

import { AccountType, AccountSubType } from "graphql/types";

import { Route } from "lib/routes";
import { UpgradeButton } from "components/featureGate/UpgradeButton";

import { DisplayMoneyColumn, DisplayMoneySubrow, GraphContainer } from "./shared";
import MetricCard, { Blurrable } from "./MetricCard";
import { MetricsAccountFragment } from "components/reports/graphql.generated";
import Calculating from "./Calculating";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { Exclamation } from "@puzzle/icons";

import { DeltaIndicator } from "./DeltaIndicator";
import { DailyTotalCashValue, DailyCashGraph } from "./DailyCashGraph";
import { dailyTotalCashPercentageDiff } from "./percentageDiffUtil";
import { useToggle } from "react-use";
import { EmptyGraph, EmptyGraphVariant } from "./EmptyGraph";

const Text = styled("div", {
  textVariant: "$bodyXS",
  fontWeight: "$bold",
});

const MainText = styled(Text, {
  color: "$gray100",
});

const DetailText = styled(Text, {
  color: "$gray500",
});

const GroupTable = styled("table", {
  borderCollapse: "separate",
  width: "100%",

  "th, td": {
    padding: "0",
    textAlign: "left",
    verticalAlign: "top",

    "&:nth-child(2)": {
      textAlign: "right",
    },
  },

  th: {
    color: "$gray600",
    fontWeight: "$heavy",
    fontSize: "$bodyXS",
    lineHeight: "16px",
    paddingBottom: "2px",
    borderBottom: "1px solid #383147",
  },

  td: {
    paddingTop: "$2",
  },

  "tr:first-child td": {
    paddingTop: "$1h",
  },
});

const Group = ({ header, accounts }: { header: string; accounts: MetricsAccountFragment[] }) => {
  const total = useMemo(
    () =>
      accounts
        .reduce((result, { todaysBalance }) => result.add(todaysBalance.balance), Big(0))
        .toNumber(),
    [accounts]
  );

  return (
    <GroupTable>
      <thead>
        <tr>
          <th>{header}</th>
          <th>{formatMoney({ currency: "USD", amount: total }, { truncateValue: true })}</th>
        </tr>
      </thead>

      <tbody>
        {accounts.map((account) => (
          <tr key={account.id}>
            <td>
              <MainText>{formatAccountName(account)}</MainText>
              <DetailText>{account.financialInstitution.name}</DetailText>
            </td>
            <td>
              <MainText>
                {formatMoney(
                  { currency: "USD", amount: account.todaysBalance.balance },
                  { truncateValue: true }
                )}
              </MainText>
            </td>
          </tr>
        ))}
      </tbody>
    </GroupTable>
  );
};

const AddMore = styled("div", {
  textAlign: "right",
  paddingBottom: "$2",
  a: {
    color: "$gray500",
    textDecoration: "underline",
  },
});

export const TodaysCash = ({
  accounts,
  loading,
  totalCash,
  dailyTotalCash,
  ingesting,
  isGraphFeatureGated,
}: {
  accounts?: MetricsAccountFragment[];
  totalCash?: Money;
  loading: boolean;
  dailyTotalCash?: DailyTotalCashValue[];
  ingesting?: boolean;
  isGraphFeatureGated?: boolean;
}) => {
  const [blur, toggleBlur] = useToggle(false);
  const { initialIngestCompleted, company } = useActiveCompany<true>();
  const groups = useMemo(() => {
    const groupedByType = groupBy(accounts, "type");
    const depositoryAccounts = groupBy(groupedByType[AccountType.Depository], "subType");
    const investmentAccounts = groupedByType[AccountType.Investment] ?? [];

    const other = (groupedByType[AccountType.Depository] ?? []).filter(
      (x) =>
        !x.subType ||
        ![AccountSubType.Savings, AccountSubType.Checking, AccountSubType.CashLike].includes(
          x.subType
        )
    );

    return {
      savings: depositoryAccounts[AccountSubType.Savings] || [],
      checking: depositoryAccounts[AccountSubType.Checking] || [],
      fintech: depositoryAccounts[AccountSubType.CashLike] || [],
      investment: investmentAccounts,
      other,
    };
  }, [accounts]);

  const showGraph = !!company?.features.metricCardsCore;
  const percentageIncrease = isPosthogFeatureFlagEnabled(FeatureFlag.DashboardShowPercentageChanged)
    ? dailyTotalCashPercentageDiff(dailyTotalCash)
    : undefined;
  const whenUpdatedText = undefined; // "Updated 20 min ago", requires actual data from BE, soon

  return (
    <MetricCard
      cardId="cash"
      loading={!initialIngestCompleted ? false : loading}
      toggleBlur={toggleBlur}
      blur={blur}
      blurEnabled={true}
      shouldHandleBlur={false}
      header="Cash"
      expandButtonOnTop={true}
      expandedContent={
        <>
          <div>
            This balance is equal to the sum of the balances across the bank accounts you have
            connected to Puzzle.
          </div>
          <Box css={{ marginTop: S["3"] }}>
            <LinkCommon
              href="https://help.puzzle.io/en/articles/9498346-cash-definitions"
              color="blue300"
              target="_blank"
            >
              Learn more about how your Cash is calculated here
            </LinkCommon>
          </Box>

          <ScrollArea
            css={{
              margin: "$4 -$2 0",
              padding: "0 $2",
              overflowX: "hidden",
            }}
          >
            <ScrollArea.Viewport>
              <Box
                css={{
                  display: "flex",
                  flexDirection: "column",
                  gap: S["4"],
                }}
              >
                {groups.checking.length > 0 && (
                  <Group key="checking" header="Checking" accounts={groups.checking} />
                )}

                {groups.savings.length > 0 && (
                  <Group key="savings" header="Savings" accounts={groups.savings} />
                )}

                {groups.fintech.length > 0 && (
                  <Group key="fintech" header="Fintech" accounts={groups.fintech} />
                )}

                {groups.investment.length > 0 && (
                  <Group
                    key="Investments"
                    header="Treasury & Investment"
                    accounts={groups.investment}
                  />
                )}

                {groups.other.length > 0 && (
                  <Group key="Other" header="Other" accounts={groups.other} />
                )}

                <AddMore>
                  <Link href={Route.integrations}>Missing any accounts?</Link>
                </AddMore>
              </Box>
            </ScrollArea.Viewport>

            <ScrollArea.Scrollbar
              orientation="vertical"
              css={{
                width: "8px !important",
              }}
            >
              <ScrollArea.Thumb />
            </ScrollArea.Scrollbar>
          </ScrollArea>
        </>
      }
      hasGraph={showGraph}
      isFeatureGated={isGraphFeatureGated}
    >
      <DisplayMoneyColumn>
        <DisplayMoneySubrow>
          {totalCash ? (
            <Blurrable active={blur}>{formatMoney(totalCash, { truncateValue: true })}</Blurrable>
          ) : (
            <Calculating />
          )}
          {totalCash && ingesting && (
            <Tooltip content="Looks like were are still ingesting data from one or more accounts.">
              <span>
                <Exclamation css={{ opacity: 0.5 }} />
              </span>
            </Tooltip>
          )}
          {percentageIncrease !== undefined && <DeltaIndicator value={percentageIncrease} />}
        </DisplayMoneySubrow>
        {whenUpdatedText && (
          <TextVE variant="bodyXS" weight="bold" color="gray500">
            {whenUpdatedText}
          </TextVE>
        )}
      </DisplayMoneyColumn>

      {showGraph && (
        <>
          <GraphContainer gateBlur={isGraphFeatureGated}>
            {(ingesting || loading) && <EmptyGraph variant={EmptyGraphVariant.SOLID} />}
            {!ingesting && !loading && <DailyCashGraph dailyTotalCash={dailyTotalCash} />}
          </GraphContainer>
          {isGraphFeatureGated && (
            <UpgradeButton
              title="Upgrade to view"
              variant="gold-outline"
              css={{
                position: "absolute",
                left: 0,
                right: 0,
                top: "50%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          )}
        </>
      )}
    </MetricCard>
  );
};
