import React, { useCallback, useEffect, useRef, useMemo } from "react";
import dynamic from "next/dynamic";

import { Tabs, GroupBy } from "@puzzle/ui";
import { styled } from "@puzzle/theme";

import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import { DashboardReportViewedProperties } from "lib/analytics/ampli/index";
import Analytics from "lib/analytics/analytics";

import { DynamicReportType, LedgerView } from "graphql/types";
import { useStickyReportContext, TABS } from "components/reports/StickyReportContext";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useReportContext } from "components/reports/ReportContext";
import { SpotlightFeatureGateModal } from "components/featureGate/SpotlightFeatureGateModal";
import { useFeatureGateStore } from "components/featureGate/featureGateStore";
import { useDashboardReportAnalyticsStore } from "components/dashboard/Dashboard/DashboardReportAnalyticsStore";
import { useSpotlightTourContext } from "components/dashboard/Dashboard/SpotlightTour/SpotlightTourContext";

const DynamicSpotlightTour = dynamic(() =>
  import("components/dashboard/Dashboard/SpotlightTour/SpotlightTour").then(
    (mod) => mod.SpotlightTour
  )
);

import { BreakoutProvider } from "./BreakoutProvider";
import { HighlightProvider } from "./HighlightProvider";
import { DeltaProvider, useDelta } from "./Filters/DeltaProvider";
import LoadingReport from "./LoadingReport";
import ReportContent from "./ReportContent";
import useLedgerReport from "./useLedgerReport";
import IngestingReport from "./IngestingReport";
import ReportContentFilters from "./ReportContentFilters";
import { DATA_TEST_IDS } from "./constants";
const Wrapper = styled("div", {
  border: "1px solid #383147",
  borderRadius: "$1",
  overflow: "hidden",

  "@media print": {
    border: "none",
  },
});

const Reports = () => {
  const { company, initialIngestCompleted } = useActiveCompany<true>();

  const {
    timePeriods,
    activeGroupBy, // reportPeriodGrouping
    stickyOptions: {
      activeTab, // reportType
      detailLevel, // reportLevel
      end, // endDate
      filter,
      preset: { key }, // dateRangePreset
      start, // startDate
      view, // basis
    },
    setStickyOptions,
  } = useStickyReportContext();

  const {
    deltaOptions: { enabled: isSpotlightOn },
  } = useDelta();

  // A custom store for tracking the timing of events.
  const { pageRequestedAt, dataReceivedAt, tableRenderedAt } = useDashboardReportAnalyticsStore();

  // This is so we only send the payload once per page load.
  const hasSentPayloadOnPageLoad = useRef(false);

  // Send the analytics payload when the table is rendered.
  useEffect(() => {
    if (tableRenderedAt > 0 && !hasSentPayloadOnPageLoad.current) {
      const dashboardReportViewedPayload: DashboardReportViewedProperties = {
        basis: view,
        dateRangePreset: key,
        endDate: end,
        highlightModeEnabled: isSpotlightOn,
        reportLevel: detailLevel,
        reportPeriodGrouping: activeGroupBy as GroupBy,
        reportType: activeTab,
        startDate: start,
        msBetweenPageRequestedAndDataReceived: dataReceivedAt - pageRequestedAt,
        msBetweenDataReceivedAndTableRendered: tableRenderedAt - dataReceivedAt,
      };

      // console.log("dashboardReportViewedPayload", dashboardReportViewedPayload); // uncomment to debug
      Analytics.dashboardReportViewed(dashboardReportViewedPayload);
      hasSentPayloadOnPageLoad.current = true;
    }
  }, [pageRequestedAt, dataReceivedAt, tableRenderedAt]);

  const { classificationsData } = useReportContext();
  const tab = useMemo(() => TABS.find((t) => t.value === activeTab) ?? TABS[0], [activeTab]);
  const type = tab.value as DynamicReportType;
  const isCashAcrrualViewSettingOn = isPosthogFeatureFlagEnabled(
    FeatureFlag.CashAcrrualReportDefaultFilter
  );

  const enableClassesAndDepts =
    (isPosthogFeatureFlagEnabled(FeatureFlag.ClassesAndDeptsM1) ?? false) &&
    type === DynamicReportType.ProfitAndLoss;

  const setTabValue = useCallback(
    (value: string) => {
      Analytics.dashboardReportTabChanged({ reportType: value as DynamicReportType });
      setStickyOptions({ activeTab: value as DynamicReportType });
      if (!isCashAcrrualViewSettingOn && value === DynamicReportType.CashActivityReport) {
        setStickyOptions({ view: LedgerView.Cash });
      }
    },
    [isCashAcrrualViewSettingOn, setStickyOptions]
  );

  const report = useLedgerReport({
    companyId: company.id,
    timePeriods,
    type,
    view:
      isCashAcrrualViewSettingOn && activeTab === DynamicReportType.CashActivityReport
        ? LedgerView.Cash
        : view,
    groupBy: activeGroupBy,
    classifications: classificationsData,
    filter,
  });

  // We need the report data outside of the tab layout.
  // We need the high watermark token for the CSV download to make sure it's the same data.
  // We essentially always return the same thing, but Apollo will still cache.
  const finalTabs = useMemo(() => {
    return TABS.map((tab) => ({
      ...tab,
      content: !initialIngestCompleted ? (
        <IngestingReport />
      ) : report.loading ? (
        <>
          <ReportContentFilters
            reportType={type}
            enableClassesAndDepts={enableClassesAndDepts}
            tab={tab}
          />
          <LoadingReport />
        </>
      ) : (
        <>
          <ReportContentFilters
            reportType={type}
            enableClassesAndDepts={enableClassesAndDepts}
            tab={tab}
          />
          <ReportContent report={report} reportType={type} />
        </>
      ),
    }));
  }, [report, type, initialIngestCompleted, enableClassesAndDepts]);

  const tabs = useMemo(() => {
    return (
      <>
        <Tabs
          variant="folder"
          items={finalTabs}
          value={activeTab}
          onValueChange={setTabValue}
          css={{ marginTop: "$1" }}
          data-testid={DATA_TEST_IDS.REPORT_TABS}
        />
      </>
    );
  }, [finalTabs, activeTab, setTabValue]);

  return (
    <HighlightProvider report={report}>
      <BreakoutProvider reportType={type}>{tabs}</BreakoutProvider>
    </HighlightProvider>
  );
};

export const ReportsContainer = ({ className }: { className?: string }) => {
  const { isInProgress } = useSpotlightTourContext();
  const isSpotlightFeatureGateModalShown = useFeatureGateStore(
    (state) => state.isSpotlightFeatureGateModalShown
  );
  const hideSpotlightFeatureGateModal = useFeatureGateStore(
    (state) => state.hideSpotlightFeatureGateModal
  );

  return (
    <Wrapper className={className}>
      <DeltaProvider>
        <Reports />
      </DeltaProvider>

      <SpotlightFeatureGateModal
        open={isSpotlightFeatureGateModalShown}
        onClose={() => {
          hideSpotlightFeatureGateModal();
        }}
      />
      {isInProgress && <DynamicSpotlightTour />}
    </Wrapper>
  );
};
