import React, { useEffect } from "react";
import { styled } from "@puzzle/theme";
import Analytics from "lib/analytics/analytics";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import { CoaType } from "graphql/types";
import { SpotlightTourProvider } from "components/dashboard/Dashboard/SpotlightTour/SpotlightTourContext";
import { ReportsContainer } from "components/dashboard/Report/ReportsContainer";
import { ReportsContainerWithStatus } from "components/dashboard/Report/ReportsContainerWithStatus";
import { IntroTour } from "components/dashboard/Dashboard/IntroTour/IntroTour";
import { ChartTile } from "components/dashboard/Dashboard/ChartTile/ChartTile";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useGetDashboardChartDataQuery } from "components/dashboard/Dashboard/ChartTile/graphql.generated";
import { StatCards } from "components/dashboard/Dashboard/StatCards";
import { StatCardsMinimal } from "components/dashboard/Dashboard/StatCardsMinimal";
import { LaunchpadBanner } from "components/launchpad/LaunchpadBanner";
import { useOnboardingProgress } from "components/launchpad/useOnboardingProgress";

const Wrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$2",
});

export const Dashboard = () => {
  const { company } = useActiveCompany<true>();
  const { showLaunchpadBanner } = useOnboardingProgress();

  const { data: dashboardChartData, loading: dashboardChartsLoading } =
    useGetDashboardChartDataQuery({
      variables: {
        companyId: company.id,
      },
      context: {
        batch: false,
      },
    });

  useEffect(() => {
    Analytics.dashboardViewed();
  }, []);

  const showSecondRowTiles = !!company?.features.metricCardsAdditional;
  const chartsLoading = !!dashboardChartsLoading && !dashboardChartData;
  const hasDecimalCoaType = company?.coaType === CoaType.Decimal; // is a Decimal company

  const isLaunchpadBannerEnabled = isPosthogFeatureFlagEnabled(FeatureFlag.onboardingLaunchpad);

  return (
    <IntroTour>
      <Wrapper>
        {isLaunchpadBannerEnabled && showLaunchpadBanner && <LaunchpadBanner />}
        {hasDecimalCoaType ? (
          <StatCardsMinimal
            charts={dashboardChartData?.company?.charts || undefined}
            chartsLoading={chartsLoading}
          />
        ) : (
          <StatCards
            charts={dashboardChartData?.company?.charts || undefined}
            chartsLoading={chartsLoading}
          />
        )}
        {!showSecondRowTiles && (
          <ChartTile
            charts={dashboardChartData?.company?.charts || undefined}
            loading={chartsLoading}
          />
        )}
        <SpotlightTourProvider>
          {isPosthogFeatureFlagEnabled(FeatureFlag.DashboardFsReportsWithStatus) ? (
            <ReportsContainerWithStatus />
          ) : (
            <ReportsContainer />
          )}
        </SpotlightTourProvider>
      </Wrapper>
    </IntroTour>
  );
};
