import { GroupBy } from "@puzzle/ui";
import { AvailableClassesResult } from "graphql/types";

export const ReportPeriodFilters = [
  { value: GroupBy.Total, label: "Totals only" },
  { value: GroupBy.Month, label: "By Months" },
  { value: GroupBy.Quarter, label: "By Quarters" },
  { value: GroupBy.Year, label: "By Years" },
];

export const buildClassificationOptions = (
  classificationsData?: AvailableClassesResult,
  flagEnabled = false
) => {
  if (!flagEnabled || !classificationsData) return [];
  return [
    {
      includeSeparator: true,
      value: classificationsData.departments.id,
      label: classificationsData.departments.name,
    },
    {
      value: classificationsData.locations.id,
      label: classificationsData.locations.name,
    },
    ...classificationsData.userCreated.map((classification) => ({
      value: classification.id,
      label: classification.name,
    })),
  ];
};
