/* eslint-disable react/display-name */
import React, { useEffect } from "react";

import { useAppRouter } from "lib/useAppRouter";
import { config } from "lib/config";
import { destroyPostLoginRedirectUrl, getPostLoginRedirectUrl } from "lib/cookies";

import type { ExtendedNextPage } from "components/common/types";
import { PrivateLayout } from "components/layout/private/layouts/PrivateLayout";
import { Dashboard } from "components/dashboard/Dashboard/Dashboard";
import { useDashboardReportAnalyticsStore } from "components/dashboard/Dashboard/DashboardReportAnalyticsStore";

const DashboardPage: ExtendedNextPage<Record<string, unknown>> = () => {
  const { goToPath } = useAppRouter();
  const { setPageRequestedAt } = useDashboardReportAnalyticsStore();

  // TODO remove in favor of login.page.ts's redirect behavior? or choose one.
  // duplicated in VRAP
  useEffect(() => {
    const redirectUrl = getPostLoginRedirectUrl();

    if (redirectUrl) {
      const url = new URL(`${config.ROOT_URL}${redirectUrl}`);
      const query = Object.fromEntries(new URLSearchParams(url.search));

      destroyPostLoginRedirectUrl();
      goToPath(url.pathname, { query });
    }
  }, [goToPath]);

  // When the component mounts, set the pageRequestedAt timestamp.
  useEffect(() => {
    setPageRequestedAt(Date.now());
  }, []);

  return <Dashboard />;
};

DashboardPage.getLayout = (page) => {
  // We want a blank but not empty title to show a header with nothing visible, this is the simplest way.
  return (
    <PrivateLayout title=" " documentTitle="Dashboard">
      {page}
    </PrivateLayout>
  );
};

export default DashboardPage;
