import React, { useCallback } from "react";
import { useRouter } from "next/router";
import { useLaunchpadStore } from "components/launchpad/launchpadStore";
import { useStickyReportContext } from "components/reports/StickyReportContext";
import { DynamicReportType } from "graphql/types";

type SpotlightTourContextType = {
  isInProgress: boolean;
  step: number;
  lastStepNumber: number;
  onBack: () => void;
  onNext: () => void;
  startTour: () => void;
  endTour: () => void;
};

const LAST_STEP_NUMBER = 3;

const SpotlightTourContext = React.createContext<SpotlightTourContextType | null>(null);

export function SpotlightTourProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const { showLaunchpadOverlay } = useLaunchpadStore();
  const { setStickyOptions, stickyOptions } = useStickyReportContext();
  const [step, setStep] = React.useState(1);
  const [isInProgress, setIsInProgress] = React.useState(false);

  const clearQuery = useCallback(() => {
    const { tour, ...queryParams } = router.query;
    router.replace(
      {
        pathname: router.pathname,
        query: queryParams,
      },
      undefined,
      { shallow: true }
    );
  }, [router]);

  React.useLayoutEffect(() => {
    if (router.query.tour === "spotlight" && !isInProgress) {
      startTour();
      clearQuery();
    }
  }, [router.query.tour, isInProgress]);

  const onBack = () => {
    if (step === 1) {
      endTour();
      showLaunchpadOverlay();
    } else {
      setStep(step - 1);
    }
  };

  const onNext = () => {
    if (step === LAST_STEP_NUMBER) {
      return endTour();
    }

    return setStep(step + 1);
  };

  const startTour = () => {
    // Ensure we're on Cash Activity tab before starting tour
    if (stickyOptions.activeTab !== DynamicReportType.CashActivityReport) {
      setStickyOptions({ activeTab: DynamicReportType.CashActivityReport });
    }

    requestAnimationFrame(() => {
      setIsInProgress(true);
      setStep(1);
    });
  };

  const endTour = () => {
    setIsInProgress(false);
  };

  return (
    <SpotlightTourContext.Provider
      value={{
        isInProgress,
        step,
        lastStepNumber: LAST_STEP_NUMBER,
        onBack,
        onNext,
        startTour,
        endTour,
      }}
    >
      {children}
    </SpotlightTourContext.Provider>
  );
}

export const useSpotlightTourContext = () => {
  const context = React.useContext(SpotlightTourContext);
  if (context === null) {
    throw new Error("useSpotlightTourContext must be used within SpotlightTourProvider");
  }
  return context;
};
