import React from "react";
import { Tag, Toolbar } from "@puzzle/ui";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import { useReportContext } from "components/reports/ReportContext";
import { noClassificationUUID } from "./ClassificationsFilter";
import { LedgerReportFilterInput } from "graphql/types";

export const FilterTags = ({
  filter,
  onRemove,
}: {
  filter?: LedgerReportFilterInput;
  onRemove?: (nextState: any) => void;
}) => {
  const { normalizedReportingClasses } = useReportContext();

  const classesM1Enabled = isPosthogFeatureFlagEnabled(FeatureFlag.ClassesAndDeptsM1);

  if (!classesM1Enabled || Object.keys(normalizedReportingClasses).length === 0) return null;
  if (
    !filter ||
    !filter.segments ||
    !filter.segments.reportingClassId ||
    !filter.segments.segmentIds
  )
    return null;

  const activeReportingClass = normalizedReportingClasses[filter.segments.reportingClassId];

  return (
    <Toolbar css={{ flexWrap: "wrap" }}>
      {filter.segments.segmentIds.map((segmentId) => {
        const appliedSegment =
          segmentId === noClassificationUUID
            ? { name: `No ${activeReportingClass.name}` }
            : activeReportingClass.availableValues.find((value) => value.id === segmentId);

        if (!appliedSegment) return null;

        return (
          <Tag
            key={segmentId}
            onRemove={() =>
              onRemove?.({
                filter: {
                  segments: {
                    reportingClassType: activeReportingClass.type,
                    reportingClassId: filter?.segments?.reportingClassId,
                    segmentIds: filter?.segments?.segmentIds.filter((id) => id !== segmentId) ?? [],
                  },
                },
              })
            }
          >
            {appliedSegment.name}
          </Tag>
        );
      })}
    </Toolbar>
  );
};
