import * as Types from '../../../../graphql/types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChartDatePointFragment = { __typename?: 'ChartDatePoint', date: CalendarDateString, values: Array<{ __typename?: 'ChartPoint', key: string, value: string }>, breakdowns?: Array<{ __typename?: 'ChartPoint', key: string, value: string } | null> | null };

export type GetDashboardChartDataQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
}>;


export type GetDashboardChartDataQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: string, charts?: { __typename?: 'Charts', updatedAt?: CalendarDateTimeString | null, cashBalance: Array<{ __typename?: 'ChartDatePoint', date: CalendarDateString, values: Array<{ __typename?: 'ChartPoint', key: string, value: string }>, breakdowns?: Array<{ __typename?: 'ChartPoint', key: string, value: string } | null> | null }>, revenue: Array<{ __typename?: 'ChartDatePoint', date: CalendarDateString, values: Array<{ __typename?: 'ChartPoint', key: string, value: string }>, breakdowns?: Array<{ __typename?: 'ChartPoint', key: string, value: string } | null> | null }>, spending: Array<{ __typename?: 'ChartDatePoint', date: CalendarDateString, values: Array<{ __typename?: 'ChartPoint', key: string, value: string }>, breakdowns?: Array<{ __typename?: 'ChartPoint', key: string, value: string } | null> | null }> } | null } | null };

export const ChartDatePointFragmentDoc = gql`
    fragment chartDatePoint on ChartDatePoint {
  date
  values {
    key
    value
  }
  breakdowns {
    key
    value
  }
}
    `;
export const GetDashboardChartDataDocument = gql`
    query getDashboardChartData($companyId: ID!) {
  company(id: $companyId) {
    id
    charts {
      updatedAt
      cashBalance {
        ...chartDatePoint
      }
      revenue {
        ...chartDatePoint
      }
      spending {
        ...chartDatePoint
      }
    }
  }
}
    ${ChartDatePointFragmentDoc}`;

/**
 * __useGetDashboardChartDataQuery__
 *
 * To run a query within a React component, call `useGetDashboardChartDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardChartDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardChartDataQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetDashboardChartDataQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardChartDataQuery, GetDashboardChartDataQueryVariables> & ({ variables: GetDashboardChartDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardChartDataQuery, GetDashboardChartDataQueryVariables>(GetDashboardChartDataDocument, options);
      }
export function useGetDashboardChartDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardChartDataQuery, GetDashboardChartDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardChartDataQuery, GetDashboardChartDataQueryVariables>(GetDashboardChartDataDocument, options);
        }
export function useGetDashboardChartDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDashboardChartDataQuery, GetDashboardChartDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDashboardChartDataQuery, GetDashboardChartDataQueryVariables>(GetDashboardChartDataDocument, options);
        }
export type GetDashboardChartDataQueryHookResult = ReturnType<typeof useGetDashboardChartDataQuery>;
export type GetDashboardChartDataLazyQueryHookResult = ReturnType<typeof useGetDashboardChartDataLazyQuery>;
export type GetDashboardChartDataSuspenseQueryHookResult = ReturnType<typeof useGetDashboardChartDataSuspenseQuery>;
export type GetDashboardChartDataQueryResult = Apollo.QueryResult<GetDashboardChartDataQuery, GetDashboardChartDataQueryVariables>;