import React from "react";
import Image from "next/image";
import { Text } from "ve";
import { Box } from "@puzzle/ve";
import { vars, S } from "@puzzle/theme";

import emptyGraph_barsThick from "public/images/dashboard/emptyGraph_barsThick.svg?url";
import emptyGraph_barsThin from "public/images/dashboard/emptyGraph_barsThin.svg?url";
import emptyGraph_solid from "public/images/dashboard/emptyGraph_solid.svg?url";
import emptyGraph_line from "public/images/dashboard/emptyGraph_line.svg?url";

export enum EmptyGraphVariant {
  BARS_THICK = "BARS_THICK",
  BARS_THIN = "BARS_THIN",
  SOLID = "SOLID",
  LINE = "LINE",
}

export const EmptyGraph = ({
  variant,
  text,
  css,
}: {
  variant?: EmptyGraphVariant;
  text?: string;
  css?: React.CSSProperties;
}) => {
  let imageVariant: string = emptyGraph_barsThick;

  switch (variant) {
    case EmptyGraphVariant.BARS_THICK:
      imageVariant = emptyGraph_barsThick;
      break;
    case EmptyGraphVariant.BARS_THIN:
      imageVariant = emptyGraph_barsThin;
      break;
    case EmptyGraphVariant.SOLID:
      imageVariant = emptyGraph_solid;
      break;
    case EmptyGraphVariant.LINE:
      imageVariant = emptyGraph_line;
      break;
  }

  return (
    <Box
      css={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        ...css,
      }}
    >
      <Image src={imageVariant} alt="empty graph" priority />

      {text && (
        <Box
          css={{
            position: "absolute",
            top: "21px",

            paddingTop: S.$1,
            paddingBottom: S.$1,
            paddingLeft: S.$2,
            paddingRight: S.$2,

            borderRadius: vars.radii[2],
            border: "1px solid rgba(255, 255, 255, 0.05)",
            backgroundColor: "rgba(25, 25, 25, 0.05)",
            backdropFilter: "blur(25px)",
          }}
        >
          <Text variant="bodyS" color="gray200">
            {text}
          </Text>
        </Box>
      )}
    </Box>
  );
};
