import React from "react";

import { keyframes, styled } from "@puzzle/theme";

const spin = keyframes({
  "0%": { transform: "rotate(0)" },
  "100%": { transform: "rotate(360deg)" },
});

const Wrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  animation: `${spin} 2s linear 0s infinite`,

  defaultVariants: {
    animate: true,
  },

  variants: {
    animate: {
      true: {},
      false: { animation: "none" },
    },
  },
});

export function SynchronizingThin() {
  return (
    <Wrapper animate>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.7354 7.28787C26.7719 11.3135 26.9623 17.8378 23.2798 22.1066L22.9858 20.3955C22.9486 20.1756 22.7388 20.0279 22.5184 20.0658C22.3208 20.0997 22.1814 20.2701 22.1814 20.4633C22.1814 20.4858 22.183 20.5085 22.187 20.532L22.622 23.0654C22.6593 23.2852 22.869 23.4329 23.0894 23.395L25.6306 22.9612C25.851 22.924 25.9967 22.7181 25.9611 22.495C25.9239 22.2752 25.7141 22.1275 25.4937 22.1654L24.0907 22.4045C27.8443 17.8175 27.5714 10.9682 23.308 6.71651C20.2063 3.62314 15.6773 2.51322 11.4877 3.82027C11.3143 3.87437 11.2035 4.03359 11.2035 4.20563C11.2035 4.2453 11.2091 4.28642 11.2221 4.32609C11.2884 4.53852 11.5161 4.65736 11.7291 4.59099C15.6303 3.37434 19.8479 4.40784 22.7354 7.28787Z"
          fill="#966DD5"
        />
        <path
          d="M18.7833 25.6732C18.7169 25.4608 18.4892 25.342 18.2763 25.4083C14.3759 26.625 10.1586 25.5917 7.27112 22.7117C3.23461 18.6853 3.04348 12.1609 6.72667 7.89299L7.02068 9.60411C7.05793 9.82393 7.26767 9.97162 7.4881 9.93375C7.70851 9.8966 7.85425 9.69302 7.81863 9.46759L7.38359 6.93414C7.34634 6.71432 7.1366 6.56663 6.91617 6.6045L4.37589 7.03837C4.17826 7.07227 4.03885 7.24268 4.03885 7.43582C4.03885 7.45836 4.04048 7.48108 4.04446 7.50452C4.08171 7.72434 4.29145 7.87203 4.51188 7.83416L5.91318 7.59505C4.16273 9.73517 3.28125 12.3633 3.28125 14.9961C3.28125 18.0061 4.42255 21.0154 6.69648 23.2835C9.79819 26.3769 14.3272 27.4868 18.5168 26.1797C18.7316 26.1132 18.8507 25.8869 18.7835 25.6737L18.7833 25.6732Z"
          fill="#966DD5"
        />
      </svg>
    </Wrapper>
  );
}

export function SynchronizingThick({ animate }: { animate?: boolean }) {
  return (
    <Wrapper animate={animate}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        fill="none"
        viewBox="0 0 10 10"
      >
        <path
          fill="#B3B2B8"
          d="M.333 9.667V8.5h1.605l-.234-.204C1.2 7.849.844 7.338.64 6.765a5.143 5.143 0 01-.307-1.736 4.6 4.6 0 01.97-2.88 4.56 4.56 0 012.53-1.67v1.225c-.7.253-1.264.683-1.691 1.29A3.452 3.452 0 001.5 5.03c0 .438.083.863.248 1.276.165.413.423.795.773 1.145l.146.146v-1.43h1.166v3.5h-3.5zm5.834-.146V8.296a3.458 3.458 0 001.691-1.29A3.452 3.452 0 008.5 4.97c0-.438-.083-.863-.248-1.276A3.407 3.407 0 007.48 2.55l-.146-.146v1.43H6.167v-3.5h3.5V1.5H8.063l.233.204c.476.476.824.994 1.043 1.553.218.56.328 1.13.328 1.714a4.6 4.6 0 01-.97 2.88 4.56 4.56 0 01-2.53 1.67z"
        ></path>
      </svg>
    </Wrapper>
  );
}
