import React from "react";
import { Area, ComposedChart, Line, ResponsiveContainer, Tooltip } from "recharts";

import { Box } from "@puzzle/ve";
import { useLocalDateFormatter } from "@puzzle/utils";
import { S, veColors as colors } from "@puzzle/theme";

import { GraphToolTip } from "./GraphToolTip";
import { GraphDateRange } from "./GraphDateRange";

export const RunwayGraph = ({
  todayCash,
  startDate,
  endDate,
}: {
  todayCash?: string;
  startDate?: string;
  endDate?: string;
}) => {
  const monthFormatter = useLocalDateFormatter({
    month: "long",
    year: "numeric",
  });

  if (!todayCash || !startDate || !endDate) {
    return null; // shows nothing until the graph is ready
  }

  const data = [
    {
      date: startDate,
      value: parseFloat(todayCash),
    },
    {
      date: endDate,
      value: "0",
    },
  ];

  return (
    <Box css={{ width: "100%", height: "100%", paddingLeft: S.$1, paddingRight: S.$1 }}>
      <ResponsiveContainer width="100%" height="85%">
        <ComposedChart data={data}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={colors.purple500} stopOpacity={1.0} />
              <stop offset="100%" stopColor={colors.purple900} stopOpacity={1.0} />
            </linearGradient>
          </defs>
          <Area type="monotone" dataKey="value" fill="url(#colorUv)" isAnimationActive={false} />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#B891E8"
            strokeWidth={3}
            dot={false}
            isAnimationActive={false}
          />

          <Tooltip
            wrapperStyle={{ outline: "none" }}
            cursor={false}
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                return (
                  <GraphToolTip date={payload[0].payload.date} value={payload[0].payload.value} />
                );
              }

              return null;
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <GraphDateRange
        startDate={startDate}
        endDate={endDate}
        css={{ paddingLeft: S.$1h, paddingRight: S.$1h, marginTop: "4px" }}
      />
    </Box>
  );
};
