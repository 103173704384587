import React, { useEffect, useMemo, useCallback, useRef } from "react";
import { useToggle } from "react-use";

import { Tooltip } from "@puzzle/ui";
import { styled, shadows } from "@puzzle/theme";
import { Crown } from "@puzzle/icons";
import { formatMoney, formatNumber } from "@puzzle/utils";

import Analytics from "lib/analytics/analytics";

import { DeltaOptions, DetailLevel } from "components/dashboard/Report/types";
import ComparisonTool from "components/dashboard/Report/Filters/ComparisonTool";
import { useDelta } from "components/dashboard/Report/Filters/DeltaProvider";
import { useStickyReportContext } from "components/reports/StickyReportContext";
import { useFeatureGateStore } from "components/featureGate/featureGateStore";
import { useIntroTourContext } from "components/dashboard/Dashboard/IntroTour/IntroTour";
import { useSpotlightTourContext } from "components/dashboard/Dashboard/SpotlightTour/SpotlightTourContext";
import { DATA_TEST_IDS } from "../constants";
const SpotlightButton = styled("button", {
  appearance: "none",
  backgroundColor: "transparent",
  transition: "box-shadow 0.15s ease-in",
  border: "none",

  height: "100%",
  fontSize: "$body",
  fontWeight: "$bold",
  letterSpacing: "$bodyXS",
  lineHeight: 1,
  color: "$gray300",

  display: "flex",
  alignItems: "center",
  padding: "0 $1",
  cursor: "pointer",
  "&:disabled": {
    color: "$gray700",
    textShadow: "none",
    cursor: "initial",
  },
});

const Container = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  $$height: "32px",
  height: "$$height",
  border: "1px solid $purple700",
  borderRadius: "$1",

  defaultVariants: {
    active: false,
    isInTour: false,
    disabled: false,
  },

  compoundVariants: [
    {
      disabled: true,
      isInTour: false,
      css: { boxShadow: "none", borderColor: "$gray700" },
    },
  ],

  variants: {
    active: {
      true: {
        [`${SpotlightButton}`]: {
          backgroundColor: "$purple700",
          color: "$gray100",
        },
      },
      false: {
        boxShadow: shadows.purple700BottomBlurMedium,
      },
    },

    disabled: {
      true: {},
      false: {},
    },

    isInTour: {
      true: {
        pointerEvents: "none",
      },
      false: {},
    },
  },
});

const ActiveValues = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gridGap: "$2",
  backgroundColor: "#1b1c29",
  padding: "0 $1h",

  // Popover.Anchor would have to be styled, which is obfuscated
  // 2px is from borders
  height: "calc($$height - 2px)",

  margin: "0",
  cursor: "pointer",
  borderRadius: "0 $1 $1 0",
  borderColor: "$purple700",

  textVariant: "$bodyXS",
  fontSize: "$body",
  fontWeight: "$bold",

  "& > *": {
    border: "none",
  },

  variants: {
    hidden: {
      true: {
        display: "none",
      },
    },
  },
});

const Operand = styled("div", {
  color: "$gray400",
});

const SPOTLIGHT_STEPS = {
  DISABLE: 2,
  ENABLE: 1,
  OPEN_DROPDOWN: 0,
} as const;

const SpotlightFilters = ({
  tab,
  disabled: _disabled,
  featureGated,
  onToggle,
}: {
  tab: string;
  disabled?: boolean;
  featureGated: boolean;
  onToggle?: (enabled: boolean) => void;
}) => {
  const { deltaOptions, setDeltaOptions } = useDelta();
  const { setStickyOptions } = useStickyReportContext();
  const { isInProgress: isTourInProgress } = useIntroTourContext();
  const {
    step: spotlightTourStep,
    endTour: endSpotlightTour,
    isInProgress: isSpotlightTourInProgress,
    lastStepNumber,
  } = useSpotlightTourContext();
  const showSpotlightFeatureGateModal = useFeatureGateStore(
    (state) => state.showSpotlightFeatureGateModal
  );
  const disabled = !isTourInProgress && (_disabled || tab !== "CashActivityReport");

  const [isDropdownOpen, toggleDropdown] = useToggle(false);
  useEffect(() => {
    if (!deltaOptions.enabled) {
      toggleDropdown(false);
    }
  }, [toggleDropdown, deltaOptions.enabled]);

  const toggleSpotlight = useCallback(() => {
    setDeltaOptions((x: DeltaOptions) => {
      const enabled = !x.enabled && tab === "CashActivityReport";
      Analytics.dashboardReportHighlightToggled({ enabled });
      onToggle?.(enabled);
      return { enabled };
    });
  }, [onToggle, setDeltaOptions, tab]);

  useEffect(() => {
    if (tab !== "CashActivityReport") {
      toggleSpotlight();
      toggleDropdown(false);
    }
  }, [toggleSpotlight, toggleDropdown, tab]);

  const showSpotlightTour = useCallback(() => {
    const stepsFromLast = lastStepNumber - spotlightTourStep;

    switch (stepsFromLast) {
      case SPOTLIGHT_STEPS.DISABLE:
        if (deltaOptions.enabled) {
          toggleSpotlight();
        }
        break;

      case SPOTLIGHT_STEPS.ENABLE:
        if (!deltaOptions.enabled) {
          toggleSpotlight();
        }
        break;

      case SPOTLIGHT_STEPS.OPEN_DROPDOWN:
        if (!isDropdownOpen && isSpotlightTourInProgress) {
          toggleDropdown(true);
          endSpotlightTour();
        }
        break;
    }
  }, [
    toggleSpotlight,
    toggleDropdown,
    lastStepNumber,
    deltaOptions.enabled,
    endSpotlightTour,
    isSpotlightTourInProgress,
    spotlightTourStep,
    isDropdownOpen,
  ]);

  useEffect(() => {
    if (!isSpotlightTourInProgress) {
      return;
    }

    showSpotlightTour();
  }, [showSpotlightTour, isSpotlightTourInProgress]);

  const activeValuesRef = useRef<HTMLDivElement | null>(null);

  // TODO Use Toolbar.Button.. I'm not sure how since there are essentially two buttons...
  const cta = useMemo(() => {
    const button = (
      <SpotlightButton
        disabled={disabled}
        onClick={() => {
          if (featureGated) {
            showSpotlightFeatureGateModal();
          } else {
            toggleSpotlight();
            if (!deltaOptions.enabled) setStickyOptions({ detailLevel: DetailLevel.Detailed });
          }
        }}
        data-testid={DATA_TEST_IDS.SPOTLIGHT_BUTTON}
      >
        Spotlight
        {featureGated && <Crown css={{ marginLeft: "$1h" }} />}
      </SpotlightButton>
    );

    const tooltipContent =
      "Spotlight highlights fluctuations, so you can track and evaluate your cash flow with ease.";

    if (disabled) {
      return button;
    }

    return (
      <Tooltip
        content={deltaOptions.enabled ? undefined : tooltipContent}
        side="right"
        arrow={false}
        open={disabled ? false : undefined}
      >
        {button}
      </Tooltip>
    );
  }, [
    deltaOptions.enabled,
    disabled,
    toggleSpotlight,
    setStickyOptions,
    featureGated,
    showSpotlightFeatureGateModal,
  ]);

  if (disabled) return null;

  return (
    <Container
      active={deltaOptions.enabled}
      isInTour={isTourInProgress}
      data-testid={DATA_TEST_IDS.SPOTLIGHT_BUTTON}
    >
      {cta}

      <ComparisonTool
        onInteractOutside={(e: any) => {
          if (
            e.currentTarget &&
            activeValuesRef.current?.contains(e.currentTarget as HTMLElement)
          ) {
            e.preventDefault();
          }
        }}
        open={isDropdownOpen}
        // TODO Re-focus after closing?
        onOpenChange={toggleDropdown}
        side="bottom"
        align="start"
        sideOffset={8}
        anchor={
          <ActiveValues
            ref={activeValuesRef}
            role="button"
            hidden={!deltaOptions.enabled}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            onClick={toggleDropdown}
          >
            <div>{formatNumber(deltaOptions.atLeastPercent, { style: "percent" })}</div>

            <Operand>AND</Operand>

            <div>
              {formatMoney(
                { currency: "USD", amount: deltaOptions.atLeastDollarAmount },
                { truncateValue: true }
              )}
            </div>
          </ActiveValues>
        }
      />
    </Container>
  );
};

export default SpotlightFilters;
