import cloneDeep from "lodash/cloneDeep";
import { AccountFragment } from "graphql/fragments/account.generated";
import Big from "big.js";
import { LedgerReconciliationBalanceSummary } from "graphql/types";
import { SingleLedgerReconciliationFragment } from "./graphql.generated";

export const accountTestId = (account: AccountFragment) =>
  account.name.toLowerCase().split(" ").join("_");

export const calcBalanceChange = (
  balanceSummary: LedgerReconciliationBalanceSummary,
  amount: Big,
  shouldAddEntry: boolean
) => {
  const newBalance = cloneDeep(balanceSummary);
  const changeAmount = Big(amount);

  if (newBalance && newBalance.totalDeposits && changeAmount.gt(0)) {
    const totalDeposits = Big(newBalance.totalDeposits.amount);
    newBalance.totalDeposits.amount = shouldAddEntry
      ? totalDeposits.plus(changeAmount).toString()
      : totalDeposits.minus(changeAmount).toString();
  } else if (newBalance && newBalance.totalPayments && changeAmount.lt(0)) {
    const totalPayments = Big(newBalance.totalPayments.amount);
    newBalance.totalPayments.amount = shouldAddEntry
      ? totalPayments.plus(changeAmount).toString()
      : totalPayments.minus(changeAmount).toString();
  }
  return newBalance;
};

export const calcTotalReconDif = (recon: SingleLedgerReconciliationFragment) => {
  const statementBalance = Big(recon.statementBalance ?? 0);
  const prevBalance = Big(recon.lastLedgerReconciliation?.statementBalance ?? 0);
  const ledgerDeposits = Big(recon.balanceSummary?.totalDeposits.amount ?? 0);
  const ledgerPayments = Big(recon.balanceSummary?.totalPayments.amount ?? 0);

  return statementBalance.minus(prevBalance.add(ledgerDeposits).plus(ledgerPayments));
};

// we want to normalize to the UI standard of showing cc deposit totals as positive
// ledger cc balances are negative (credits) but we display them as positive
// for UI purposes or GW purposes, cc balances are positive. For ledger purposes, they are negative
export const calcDepositsAndPayments = (
  isCreditCard: boolean,
  ledgerDeposits: Big,
  ledgerPayments: Big
) => {
  const deposits = isCreditCard ? ledgerPayments : ledgerDeposits;
  const payments = isCreditCard ? ledgerDeposits : ledgerPayments;

  return {
    totalDeposits: isCreditCard ? deposits.neg() : deposits,
    totalPayments: isCreditCard ? payments : payments.abs(),
  };
};
