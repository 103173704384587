import React from "react";

import { formatMoney, parseDate, useLocalDateFormatter } from "@puzzle/utils";

import { Text } from "ve";
import { Box } from "@puzzle/ve";
import { S, veColors as colors, vars } from "@puzzle/theme";

export const GraphToolTip = ({
  date,
  value,
  includeDate,
}: {
  date: string;
  value: number;
  includeDate?: boolean;
}) => {
  const monthFormatter = useLocalDateFormatter({
    month: "long",
    day: includeDate ? "numeric" : undefined,
    year: "numeric",
  });

  return (
    <Box
      css={{
        display: "flex",
        flexDirection: "column",
        gap: S["$1"],
        padding: S["$1h"],
        backgroundColor: colors.black,
        borderRadius: vars.radii["1"],
        borderColor: colors.black,
      }}
    >
      <Text variant="bodyXS" color="gray300" weight="bold">
        {monthFormatter.format(parseDate(date))}
      </Text>
      <Text variant="bodyXS" color="white" weight="bold">
        {formatMoney({ amount: value as number }, { truncateValue: true })}
      </Text>
    </Box>
  );
};

export const SpendGraphToolTip = ({
  date,
  payrollValue,
  nonPayrollValue,
}: {
  date: string;
  payrollValue: number;
  nonPayrollValue: number;
}) => {
  const monthFormatter = useLocalDateFormatter({
    month: "long",
    year: "numeric",
  });

  return (
    <Box
      css={{
        display: "flex",
        flexDirection: "column",
        gap: S["$1"],
        padding: S["$1h"],
        backgroundColor: colors.black,
        borderRadius: vars.radii["1"],
        borderColor: colors.black,
        minWidth: "180px",
      }}
    >
      <Text variant="bodyXS" color="gray300" weight="bold">
        {monthFormatter.format(parseDate(date))}
      </Text>
      <Box
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: S.$0h,
        }}
      >
        <Box
          css={{
            width: "4px",
            height: "4px",
            borderRadius: "2px",
            backgroundColor: colors.pink500,
          }}
        />
        <Text variant="bodyXS" color="gray300" weight="bold">
          Non-Payroll
        </Text>
        <Box css={{ marginLeft: "auto" }}>
          <Text variant="bodyXS" color="white" weight="bold">
            {formatMoney({ amount: nonPayrollValue as number }, { truncateValue: true })}
          </Text>
        </Box>
      </Box>

      <Box
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: S.$0h,
        }}
      >
        <Box
          css={{
            width: "4px",
            height: "4px",
            borderRadius: "2px",
            backgroundColor: colors.purple500,
          }}
        />
        <Text variant="bodyXS" color="gray300" weight="bold">
          Payroll
        </Text>
        <Box css={{ marginLeft: "auto" }}>
          <Text variant="bodyXS" color="white" weight="bold">
            {formatMoney({ amount: payrollValue as number }, { truncateValue: true })}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
