import React from "react";
import Icon, { IconProps } from "../Icon";
import { externalBrandColors } from "@puzzle/theme";

export default function Runway({
  viewBox = "0 0 48 48",
  width = 48,
  height = 48,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <defs>
        <filter
          id="filter0_dd_376_636"
          width="80"
          height="80"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0705882 0 0 0 0 0.0901961 0 0 0 0 0.0941176 0 0 0 0.08 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_376_636"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0705882 0 0 0 0 0.0901961 0 0 0 0 0.0941176 0 0 0 0.04 0"></feColorMatrix>
          <feBlend in2="effect1_dropShadow_376_636" result="effect2_dropShadow_376_636"></feBlend>
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_376_636" result="shape"></feBlend>
        </filter>
      </defs>
      <g filter="url(#filter0_dd_376_636)" transform="translate(-15.823 -7.716)">
        <rect
          width="48"
          height="48"
          x="16"
          y="8"
          fill={externalBrandColors.runway.logoBackground}
          rx="12"
        ></rect>
        <path
          fill={externalBrandColors.runway.fillColor}
          d="M33.133 20l18.477.39.39 18.48-5.132 5.132-.29-14.807-11.95 11.95-3.773-3.773 11.95-11.95L28 25.131 33.133 20z"
        ></path>
      </g>
    </Icon>
  );
}
