import { create } from "zustand";

type PostOnboardModalState = {
  isPostOnboardModalShown: boolean;
  isPostOnboardModalProgressShown: boolean;
  showPostOnboardModal: () => void;
  hidePostOnboardModal: () => void;
  activePanel: number;
  setActivePanel: (val: number) => void;
  setIsPostOnboardModalProgressShown: (isPostOnboardModalProgressShown: boolean) => void;
};

export const usePostOnboardModalStore = create<PostOnboardModalState>()((set) => ({
  isPostOnboardModalShown: false,
  isPostOnboardModalProgressShown: true,
  showPostOnboardModal: () => set({ isPostOnboardModalShown: true }),
  hidePostOnboardModal: () => set({ isPostOnboardModalShown: false }),
  activePanel: 0,
  setActivePanel: (val) => set({ activePanel: val }),
  setIsPostOnboardModalProgressShown: (isPostOnboardModalProgressShown: boolean) =>
    set({ isPostOnboardModalProgressShown }),
}));
