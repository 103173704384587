import React from "react";

import { Text } from "ve";
import { Box } from "@puzzle/ve";
import { CaretUp, CaretDown } from "@puzzle/icons";
import { S, veColors as colors } from "@puzzle/theme";

export const DeltaIndicator = ({ value }: { value: number }) => {
  const increasing = value >= 0;
  const actualNumber = Math.abs(value).toFixed(2);

  return (
    <Box
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: S.$0h,
      }}
    >
      {value === 0 ? null : increasing ? (
        <CaretUp color={colors.green500} />
      ) : (
        <CaretDown color={colors.red300} />
      )}

      <Text variant="bodyXS" weight="bold" color={increasing ? "green500" : "red300"}>
        {`${actualNumber}%`}
      </Text>
    </Box>
  );
};
