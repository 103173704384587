import React from "react";

import { ChartDatePoint, MetricOnDate } from "graphql/types";

import { Cell, Bar, ComposedChart, ResponsiveContainer, Tooltip } from "recharts";

import { GraphToolTip } from "./GraphToolTip";
import { GraphDateRange } from "./GraphDateRange";

import { S, veColors as colors } from "@puzzle/theme";

const extractChartDatePointData = (
  data: ChartDatePoint[],
  chartDatePointDataKey: string
): BarChartGraphData[] => {
  return data.map((x) => ({
    date: x.date,
    value: parseFloat(x.values.find((x) => x.key === chartDatePointDataKey)!.value),
  }));
};

const extracMetricOnData = (data: MetricOnDate[]): BarChartGraphData[] => {
  return data.map((x) => ({
    date: x.date,
    value: parseFloat(x.metric?.amount),
  }));
};

export type BarChartGraphData = {
  date: string;
  value: number;
};

const BarChartGraph = ({ data, axisKey }: { data?: BarChartGraphData[]; axisKey: string }) => {
  if (!data || data.length === 0) {
    return null; // shows nothing until the graph is ready
  }

  const lastElementIndex = data.length - 1;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart data={data}>
        <defs>
          <linearGradient id="colorUv_bcg" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={colors.purple500} stopOpacity={1.0} />
            <stop offset="100%" stopColor={colors.purple900} stopOpacity={1.0} />
          </linearGradient>
          <linearGradient id="colorUv_bcg_inverted" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={colors.purple900} stopOpacity={1.0} />
            <stop offset="100%" stopColor={colors.purple500} stopOpacity={1.0} />
          </linearGradient>

          <pattern
            id="diagPattern"
            width="4"
            height="4"
            patternUnits="userSpaceOnUse"
            patternTransform="rotate(45)"
          >
            <rect width="2" height="4" fill="white" />
          </pattern>

          <mask id="maskWithPattern">
            <rect x="0" y="0" width="100%" height="100%" fill="url(#diagPattern)" />
          </mask>
        </defs>

        <Bar
          isAnimationActive={false}
          yAxisId={axisKey}
          name={axisKey}
          radius={[4, 4, 4, 4]}
          dataKey="value"
        >
          {data.map((value, index) => (
            <Cell
              key={`cell-${index}`}
              fill={value.value < 0 ? "url(#colorUv_bcg_inverted)" : "url(#colorUv_bcg)"}
              mask={index === lastElementIndex ? "url(#maskWithPattern)" : ""}
            />
          ))}
        </Bar>

        <Tooltip
          wrapperStyle={{ outline: "none" }}
          cursor={false}
          content={({ active, payload }) => {
            if (active && payload && payload.length) {
              return (
                <GraphToolTip date={payload[0].payload.date} value={payload[0].payload.value} />
              );
            }

            return null;
          }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export const ChartDatePointDataBarGraph = ({
  data,
  chartDatePointDataKey,
}: {
  data?: ChartDatePoint[];
  chartDatePointDataKey: string;
}) => {
  if (!data) {
    return null;
  }

  const mappedData = extractChartDatePointData(data, chartDatePointDataKey);

  return <BarChartGraph data={mappedData} axisKey={chartDatePointDataKey} />;
};

export const BankBurnBarGraph = ({ data }: { data?: MetricOnDate[] }) => {
  if (!data) {
    return null;
  }

  const startDate = data && data[0]?.date;
  const endDate = data && data[data.length - 1]?.date;

  const mappedData = extracMetricOnData(data);

  return (
    <>
      <BarChartGraph data={mappedData} axisKey="bank-burn" />
      <GraphDateRange
        startDate={startDate}
        endDate={endDate}
        css={{ paddingLeft: S.$1h, paddingRight: S.$1h, marginTop: "4px" }}
      />
    </>
  );
};

export const TotalBurnBarGraph = ({ data }: { data?: ChartDatePoint[] }) => {
  if (!data) {
    return null;
  }

  const startDate = data && data[0]?.date;
  const endDate = data && data[data.length - 1]?.date;

  return (
    <>
      <ChartDatePointDataBarGraph data={data} chartDatePointDataKey="net-burn" />

      <GraphDateRange
        startDate={startDate}
        endDate={endDate}
        css={{ paddingLeft: S.$1h, paddingRight: S.$1h, marginTop: "4px" }}
      />
    </>
  );
};
