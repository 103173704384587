import React, { useCallback, useMemo, useState } from "react";
import { GroupBy, Select, Toolbar, DateRangePicker, useToasts, ReportGroupBy } from "@puzzle/ui";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import Analytics from "lib/analytics/analytics";
import type { ActiveCompanyFragment } from "components/companies/graphql.generated";
import { useActiveCompany, PricingFeatures } from "components/companies/ActiveCompanyProvider";
import { getViewOptions } from "components/companies/common";
import { StickyState, useStickyReportContext } from "components/reports/StickyReportContext";
import SpotlightButton from "./SpotlightButton";
import { parseDate } from "@puzzle/utils";
import { useDelta } from "./DeltaProvider";
import { DynamicReportType, LedgerReportFilterInput, LedgerView } from "graphql/types";
import { ReportPeriodFilters } from "./sharedReportFilters";
import { isGroupedByPeriod } from "../reportClassificationUtils";
import { allowableCustomDateRange, getCurrentReportPreset } from "components/reports/reportPresets";
import { ClassificationsFilter, noClassificationUUID } from "./ClassificationsFilter";
import { useReportContext } from "components/reports/ReportContext";
import { AvailableReportingClassFragment } from "components/common/hooks/graphql.generated";
import { Text } from "ve";
import { Box } from "@puzzle/ve";
import { spotlightWarningContainerCss } from "./Filters.css";
import { DATA_TEST_IDS } from "../constants";

const DateRangeFilter = ({ disabled }: { enableClassesAndDepts?: boolean; disabled?: boolean }) => {
  const { stickyOptions, setStickyOptions, dateTimeGroupBy, activeGroupBy } =
    useStickyReportContext();
  const { preset, start, end } = stickyOptions;
  const dependentPresets = getCurrentReportPreset(activeGroupBy);
  const { toast } = useToasts();

  const customPresetObj = dependentPresets[dependentPresets.length - 1];
  const curPreset = dependentPresets.find((p) => p.key === preset.key) || customPresetObj;
  return (
    <DateRangePicker
      inferCustomPresets={false}
      value={[parseDate(start), parseDate(end)]}
      view={dateTimeGroupBy === "total" ? "month" : dateTimeGroupBy}
      groupBy={dateTimeGroupBy}
      presets={dependentPresets}
      preset={curPreset}
      onChange={([start, end], rangePreset) => {
        const checkDateRange = allowableCustomDateRange(start.toString(), end.toString());
        if (checkDateRange.isAllowed) {
          setStickyOptions({
            start: start.toString(),
            end: end.toString(),
            preset: rangePreset ?? customPresetObj,
          });
        } else {
          toast({ status: "error", message: checkDateRange.message });
        }
        Analytics.dashboardReportFilterChanged({
          startDate: start.toString(),
          endDate: end.toString(),
        });
      }}
      disabled={disabled}
      showInputs
    />
  );
};

const buildClassificationOptions = (
  sortedClassifications: AvailableReportingClassFragment[],
  flagEnabled = false
) => {
  if (!flagEnabled || sortedClassifications.length <= 0) return [];

  return sortedClassifications.map((reportingClass, index) => {
    return {
      includeSeparator: index === 0,
      value: reportingClass.id,
      label: reportingClass.name,
    };
  });
};

const GroupingDropdown = React.memo(function GroupingDropdown({
  disabled,
  tab,
  enableClassesAndDepts = false,
}: {
  tab: DynamicReportType;
  disabled?: boolean;
  enableClassesAndDepts?: boolean;
}) {
  const { sortedClassifications, normalizedReportingClasses } = useReportContext();
  const { stickyOptions, setStickyOptions, activeGroupBy, isGroupByLoading } =
    useStickyReportContext();
  const { groupByBs } = stickyOptions;
  const { deltaOptions } = useDelta();
  const alwaysAllowGroupingDropdown = isPosthogFeatureFlagEnabled(
    FeatureFlag.DashboardSpotlightShowGroupingDropdown
  );

  const normalizedReportingClassesKeys = Object.keys(normalizedReportingClasses);

  const classificationsOptions = buildClassificationOptions(
    sortedClassifications,
    enableClassesAndDepts
  );

  const disabledActually = alwaysAllowGroupingDropdown
    ? disabled
    : disabled || deltaOptions.enabled;

  const getGroupByOptions = () => {
    if (enableClassesAndDepts) {
      return [...ReportPeriodFilters, ...classificationsOptions];
    }
    if (tab === DynamicReportType.BalanceSheet) {
      return ReportPeriodFilters.filter((f) => f.value !== GroupBy.Total);
    }
    return ReportPeriodFilters;
  };

  return (
    <Toolbar.Button variant="menu" disabled={disabledActually} asChild>
      <Select
        instruction={enableClassesAndDepts ? "Columns" : "Pick a view option"}
        value={isGroupByLoading ? "Loading..." : activeGroupBy}
        variant="filter"
        onSelectionChange={(value) => {
          let stickyOptionsUpdate: Partial<StickyState> = {};
          let reportPeriodGrouping = value;
          if (isGroupedByPeriod(value)) {
            const toSetGroupedByBs = value !== GroupBy.Total ? value : groupByBs;
            stickyOptionsUpdate = {
              groupBy: value as GroupBy,
              groupByPl: value as ReportGroupBy,
              groupByBs: toSetGroupedByBs as GroupBy,
            };
          } else {
            stickyOptionsUpdate = { groupByPl: value };
          }
          const stickyOptionsFilterSegments = stickyOptions.filter?.segments;

          if (
            !isGroupedByPeriod(value) &&
            stickyOptionsFilterSegments?.reportingClassId &&
            stickyOptionsFilterSegments?.reportingClassId !== value
          ) {
            stickyOptionsUpdate.filter = {};
          }

          if (!isGroupedByPeriod(value)) {
            reportPeriodGrouping = normalizedReportingClasses[value]?.type?.toLowerCase();
          }

          setStickyOptions(stickyOptionsUpdate);
          Analytics.dashboardReportFilterChanged({
            reportPeriodGrouping: reportPeriodGrouping as GroupBy,
          });
        }}
        placeholder="View Option"
        options={getGroupByOptions()}
        data-testid={DATA_TEST_IDS.REPORT_VIEW_OPTION}
      />
    </Toolbar.Button>
  );
});

const ViewDropdown = ({
  tab,
  company,
  disabled,
}: {
  tab: string;
  company: ActiveCompanyFragment;
  disabled: boolean;
  showViewDropdown?: boolean;
}) => {
  const { stickyOptions, setStickyOptions } = useStickyReportContext();
  const { view } = stickyOptions;
  const viewOptions = useMemo(() => getViewOptions(company), [company]);

  return (
    <Toolbar.Button disabled={disabled || tab === "CashActivityReport"} variant="menu" asChild>
      <Select
        instruction="Select a report basis"
        value={view}
        onSelectionChange={(view) => {
          setStickyOptions({ view: view as LedgerView });
          Analytics.dashboardReportFilterChanged({ basis: view });
        }}
        options={viewOptions.map(({ value, label }) => ({
          value: value,
          label,
        }))}
        variant="filter"
        data-testid={DATA_TEST_IDS.REPORT_BASIS_OPTION}
      />
    </Toolbar.Button>
  );
};

export const Filters = ({
  tab,
  disabled,
  hideViewDropdown = false,
}: {
  tab: string;
  disabled: boolean;
  hideViewDropdown?: boolean;
}) => {
  const { company, pricePlanFeatureEnabled } = useActiveCompany<true>();
  const {
    stickyOptions: { filter, groupByPl },
    setStickyOptions,
  } = useStickyReportContext();
  const { normalizedReportingClasses } = useReportContext();
  const [showSpotlightWarning, setShowSpotlightWarning] = useState(false);
  const [isSpotlightEnabled, setIsSpotlightEnabled] = useState(false);

  const isSpotlightButtonDisabled = disabled || groupByPl === GroupBy.Total;
  const featureGated = !pricePlanFeatureEnabled.has(PricingFeatures.spotlight);
  const handleFiltersChange = useCallback(
    (update: { filter: LedgerReportFilterInput }) => {
      const filterSegments = update.filter?.segments;
      const reportingClassType = normalizedReportingClasses[filterSegments?.reportingClassId]?.type;

      Analytics.dashboardReportFilterChanged({
        reportingClassFilterType: reportingClassType,
        reportingClassFilterCount: filterSegments?.segmentIds?.length ?? 0,
        reportingClassFilterIncludesNone:
          filterSegments?.segmentIds?.includes(noClassificationUUID) ?? false,
      });
      setStickyOptions(update);
    },
    [normalizedReportingClasses, setStickyOptions]
  );

  const classesM1Enabled = isPosthogFeatureFlagEnabled(FeatureFlag.ClassesAndDeptsM1);
  const enableClassesAndDepts = classesM1Enabled && tab === DynamicReportType.ProfitAndLoss;

  return (
    <Toolbar
      css={{
        flexWrap: "wrap",
        position: "relative",
        "@media print": {
          display: "none",
        },
      }}
    >
      <GroupingDropdown
        disabled={disabled}
        key="interval-filter"
        enableClassesAndDepts={enableClassesAndDepts}
        tab={tab as DynamicReportType}
      />
      <Box
        onMouseEnter={() => setShowSpotlightWarning(true)}
        onMouseLeave={() => setShowSpotlightWarning(false)}
      >
        <DateRangeFilter
          enableClassesAndDepts={enableClassesAndDepts}
          disabled={disabled}
          key="date-filter"
        />
      </Box>
      {enableClassesAndDepts && (
        <ClassificationsFilter
          key="classifications-filter"
          onChange={handleFiltersChange}
          filter={filter}
          groupBy={groupByPl}
          disabled={disabled}
        />
      )}
      {!hideViewDropdown && company.views && company.views.length > 0 && (
        <ViewDropdown tab={tab} company={company} disabled={disabled} />
      )}
      <SpotlightButton
        tab={tab}
        disabled={isSpotlightButtonDisabled}
        featureGated={!!featureGated}
        onToggle={setIsSpotlightEnabled}
      />
      {showSpotlightWarning && isSpotlightEnabled && (
        <div className={spotlightWarningContainerCss}>
          <Text variant="bodyXS" color="gray50">
            To change date range, turn off Spotlight
          </Text>
        </div>
      )}
    </Toolbar>
  );
};
