/*

  This hook does the following:

  1. Looks for the ledger report data from the Apollo cache.
  2. If the cache doesn't have the data, it fetches a partial report.
  3. Once the partial report is loaded, it fetches the full report.
  (This is done to show the user a quicker view of the report while the full report is loading.)
  4. Once all the data is loaded and cached, it builds the report and returns it.

  This hook also handles the following:
  
  A. State management for the status of the report fetching process.
  B. Analytics tracking for when the data is received.
*/

import { useMemo, useEffect, useState, useRef, useCallback } from "react";
import { GroupBy } from "@puzzle/ui";
import { LedgerReportColumnBy, LedgerView, useLedgerReportQuery } from "graphql/types";
import {
  getClassificationColumns,
  getColumnBy,
  getReportIntervals,
} from "./reportClassificationUtils";
import { useDashboardReportAnalyticsStore } from "components/dashboard/Dashboard/DashboardReportAnalyticsStore";
import { useBuildReport } from "./useBuildReport";
import {
  LedgerReportStatus,
  useFetchLedgerReportProps,
  initialReportResponse,
  ReportResponseType,
} from "components/dashboard/utils/fetchLedgerReport/types";
import { shouldShowLoadingUI } from "components/dashboard/utils/fetchLedgerReport/statusGroups";
import { determineLedgerReportStatus } from "components/dashboard/utils/fetchLedgerReport/determineLedgerReportStatus";
import { LedgerReport } from "components/dashboard/utils/fetchLedgerReport/types";
import isEqual from "lodash/isEqual";

export const useFetchLedgerReport = ({
  companyId,
  initialIngestCompleted,
  timePeriods,
  type,
  view,
  groupBy = GroupBy.Month,
  classifications,
  filter = {},
}: useFetchLedgerReportProps) => {
  /*
    🧪 CREATE PARAMETERS FOR THE PAYLOAD 🧪

    Process the parameters sent to create the payload for the query.

    Why aren't we just collecting the proper complete payload from the calling component?
    Because this hook is copied from another hook in use (useLedgerReport.ts)
    which is used in multiple components.

    So to make transitioning to this new hook easier, we're keeping the same signature.
    Once we've transitioned all components to this new hook, we can change how we call it.
  */

  const columnBy = getColumnBy(groupBy);
  const waitForClassification = columnBy === LedgerReportColumnBy.Segment && !classifications;
  const isDataIngestingOrWaiting = !initialIngestCompleted || waitForClassification;
  const intervals = { byPeriod: getReportIntervals(timePeriods, columnBy, type), byDate: [] };
  const segments = getClassificationColumns(filter, columnBy, groupBy, classifications);
  const queryFilter = columnBy === LedgerReportColumnBy.Interval ? filter : {};
  const queryView = view || LedgerView.Cash;
  const initialStatus = isDataIngestingOrWaiting
    ? LedgerReportStatus.INGESTING_OR_WAITING_ON_DATA
    : LedgerReportStatus.FULL_DATA_FROM_APOLLO_CACHE_LOADING;

  // used in error reporting during the determination of the next status
  const errorMetadata = {
    companyId,
    timePeriods,
    type,
    view,
    groupBy,
    segments,
    queryFilter,
    isDataIngestingOrWaiting,
  };

  /*
    🏠 STATE FOR THIS HOOK 🏠
  */

  // Report we want to display
  const [reportResponse, setReportResponse] = useState<ReportResponseType>(initialReportResponse);

  // Statuses of where we are in the fetching process
  const [status, setStatus] = useState<LedgerReportStatus>(initialStatus);

  /*
     💰 SEND THE PAYLOAD TO THE APPROPRIATE FETCH QUERY AND RETURN THE RESPONSE 💰  
     
     We use the values in the skip parameter to control when the query is executed.
  */

  // QUERY 1 - CHECK CACHE
  const cacheResponse = useLedgerReportQuery({
    fetchPolicy: "cache-only",
    skip: status !== LedgerReportStatus.FULL_DATA_FROM_APOLLO_CACHE_LOADING,
    context: { batch: false },
    variables: {
      input: {
        companyId,
        config: {
          intervals,
          columns: { segments },
          filter: queryFilter,
          columnBy,
        },
        type,
        view: queryView,
      },
    },
  });

  // QUERY 2 - FETCH PARTIAL REPORT
  const partialReportResponse = useLedgerReportQuery({
    fetchPolicy: "no-cache",
    skip: status !== LedgerReportStatus.PARTIAL_DATA_FROM_NETWORK_LOADING,
    context: { batch: false },
    variables: {
      input: {
        companyId,
        config: {
          // These options are what make it a "partial" report
          expansionOptions: {
            expandRevenueSources: false,
            expandVendors: false,
          },
          intervals,
          columns: { segments },
          filter: queryFilter,
          columnBy,
        },
        type,
        view: queryView,
      },
    },
  });

  // QUERY 3 - FETCH FULL REPORT
  const fullReportResponse = useLedgerReportQuery({
    fetchPolicy: "network-only",
    skip: status !== LedgerReportStatus.FULL_DATA_FROM_NETWORK_LOADING,
    context: { batch: false },
    variables: {
      input: {
        companyId,
        config: {
          intervals,
          columns: { segments },
          filter: queryFilter,
          columnBy,
        },
        type,
        view: queryView,
      },
    },
  });

  /*
    🧠 STATUS STATE MANAGEMENT 🧠

    This effect orchestrates the progress through the various fetch stages:
    1. Check if data exists in cache
    2. If no cache hit, fetch partial data for quick preview
    3. After partial data loads, fetch complete data for full details
    
    Each stage waits for the previous to complete.    
  */

  useEffect(() => {
    // Next, use this state management function to determine the next status and report response
    const ledgerReportStatus = determineLedgerReportStatus({
      currentStatus: status,
      cacheResponse,
      partialReportResponse,
      fullReportResponse,
      errorMetadata,
    });

    if (ledgerReportStatus.nextStatus !== status) {
      setStatus(ledgerReportStatus.nextStatus);
    }
    if (ledgerReportStatus.reportResponse) {
      setReportResponse(ledgerReportStatus.reportResponse);
    }
  }, [isDataIngestingOrWaiting, cacheResponse, partialReportResponse, fullReportResponse, status]);

  // Memoize the report data
  const ledgerReport = useMemo(
    () => (reportResponse.data ? reportResponse.data.ledgerReport : undefined),
    [reportResponse.data]
  );

  /*
    📈 ONCE WE'VE RECEIVED THE DATA, LOG IT IN ANALYTICS 📈
  */

  const { setDataReceivedAt } = useDashboardReportAnalyticsStore();

  // We only want to set dataReceivedAt once, the first time we get data
  // We don't want to update this value when we decorate the data later on.
  const hasSetDataReceivedAt = useRef(false);

  // Once we have data, set dataReceivedAt in the analytics store
  useEffect(() => {
    if (hasSetDataReceivedAt.current) return; // if we've already set dataReceivedAt
    if (
      reportResponse.data?.ledgerReport // as soon as we get any report data on page load
      // This is to measure the effect of getting partial data first.
    ) {
      setDataReceivedAt(Date.now());
      hasSetDataReceivedAt.current = true;
    }
  }, [reportResponse.data?.ledgerReport, setDataReceivedAt]);

  /*
    ⭐ FETCH A NEW REPORT WHEN THE VARIABLES CHANGE FROM USER INPUT ⭐

    When the user changes the parameters, we want to refetch the report.
    1. Track the previous parameters to detect changes that should trigger a refetch.
    2. When the query parameters change, reset everything to trigger a new fetch cycle.
  */

  // Set up a ref to track the previous parameters
  const paramsRef = useRef({
    companyId,
    type,
    view,
    groupBy,
    timePeriods,
    segments,
    queryFilter,
  });

  // When we detect a change in query parameters, reset the status to trigger a new fetch cycle
  useEffect(() => {
    const haveParamsChanged =
      paramsRef.current.companyId !== companyId ||
      paramsRef.current.type !== type ||
      paramsRef.current.view !== view ||
      paramsRef.current.groupBy !== groupBy ||
      // Use deep comparison for these params
      !isEqual(paramsRef.current.timePeriods, timePeriods) ||
      !isEqual(paramsRef.current.segments, segments) ||
      !isEqual(paramsRef.current.queryFilter, queryFilter);

    if (!haveParamsChanged) return; // exit early if no changes

    // Update ref with new values
    paramsRef.current = {
      companyId,
      type,
      view,
      groupBy,
      timePeriods,
      segments,
      queryFilter,
    };

    // Reset status and report response to trigger a new fetch cycle
    setStatus(initialStatus);
    setReportResponse(initialReportResponse);
  }, [companyId, type, view, groupBy, timePeriods, segments, queryFilter]);

  /*
    🔄  MANUAL REFRESH FUNCTION THAT WILL REFETCH THE REPORT 🔄 

    When called, forces a fetch from network, bypassing cache.
    Used to get the latest data when the user requests it.
  */

  const refetch = useCallback(() => {
    // Clear the current data to avoid stale data display
    setReportResponse(initialReportResponse);

    // Skip cache and go directly to fetching from network
    setStatus(
      isDataIngestingOrWaiting
        ? LedgerReportStatus.INGESTING_OR_WAITING_ON_DATA
        : LedgerReportStatus.PARTIAL_DATA_FROM_NETWORK_LOADING
    );

    // Do not set dataReceivedAt here, as we only want to track the initial data fetch
  }, [isDataIngestingOrWaiting, status]);

  /*
    👷 BUILD THE REPORT FROM THE DATA WE'VE RECEIVED 👷
  */

  // console.log("First line: ", ledgerReport?.lines[0]); // uncomment to debug
  // console.log({ ledgerReport });

  const builtReport = useBuildReport({
    ledgerReport: ledgerReport as LedgerReport,
    columnBy,
    timePeriods,
    type,
    // this loading prop is passed down and used in how we display the report status in older components
    // TODO: refactor older components to use the status instead
    loading: shouldShowLoadingUI(status),
  });

  return {
    ...builtReport,
    refetch,
    status,
  };
};
