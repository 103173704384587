import React, { useEffect, useMemo, useState } from "react";
import { Loader } from "ve";
import { useReconViewerContext } from "../ReconContext";
import { ReconLayout } from "./Layout";
import { useSuggestedEndingBalance } from "./useSuggestedEndingBalance";
import { useStartReconciliation } from "./useStartReconciliation";
import { useQueryState } from "next-usequerystate";
import { StartReconForm } from "./StartReconForm";
export const CREATE_AUTOMATICALLY_QUERY_KEY = "createAutomatically";

export const NewRecon = () => {
  const [createAutoReconAttempted, setCreateReconAttempted] = useState(false);
  const [createAutomatically] = useQueryState(CREATE_AUTOMATICALLY_QUERY_KEY);

  const { startRecon } = useStartReconciliation();
  const { activeLedgerAccount, isEditor } = useReconViewerContext();

  const { suggestedEndDate, suggestedBalance, loading } = useSuggestedEndingBalance({
    ledgerAccount: activeLedgerAccount,
  });

  useEffect(() => {
    if (suggestedBalance && suggestedEndDate && !createAutoReconAttempted && createAutomatically) {
      // go ahead and make a recon - we have an estimated balance and enddate
      startRecon({
        endingBalance: suggestedBalance.amount,
        endingDate: suggestedEndDate.toString(),
      });
      setCreateReconAttempted(true);
    }
  }, [
    suggestedBalance,
    suggestedEndDate,
    createAutoReconAttempted,
    startRecon,
    createAutomatically,
  ]);

  const willCreateAutomatically = useMemo(() => {
    return createAutomatically && (loading || (suggestedBalance && suggestedEndDate));
  }, [createAutomatically, loading, suggestedBalance, suggestedEndDate]);

  const loader = useMemo(
    () =>
      loading || willCreateAutomatically || !activeLedgerAccount ? <Loader size={32} /> : null,
    [loading, willCreateAutomatically]
  );

  return loader ? (
    loader
  ) : (
    <ReconLayout.Layout>
      <ReconLayout.HeaderLayout
        activeLedgerAccount={activeLedgerAccount}
      ></ReconLayout.HeaderLayout>

      <ReconLayout.Content
        canEditFiles={isEditor}
        headerElement={
          <StartReconForm suggestedBalance={suggestedBalance} suggestedEndDate={suggestedEndDate} />
        }
      ></ReconLayout.Content>
    </ReconLayout.Layout>
  );
};
