import { LedgerReportStatus, ReportResponseType } from "./types";
import { reportError } from "lib/errors/errors";

export type LedgerReportStateResult = {
  nextStatus: LedgerReportStatus;
  reportResponse: ReportResponseType | null;
};

export type DetermineLedgerReportStatusParams = {
  currentStatus: LedgerReportStatus;
  cacheResponse: ReportResponseType;
  partialReportResponse: ReportResponseType;
  fullReportResponse: ReportResponseType;
  errorMetadata: Record<string, unknown>;
};

/**
 * Determines the next state in the ledger report state machine
 *
 * @param params - Object containing all parameters for the ledger report state determination
 * @param params.currentStatus - Current status of the report fetching process
 * @param params.cacheResponse - Apollo query response from cache
 * @param params.partialReportResponse - Network query response for partial report
 * @param params.fullReportResponse - Network query response for full report
 * @param params.errorMetadata - Metadata to include in error reports
 * @returns Object with the next status and report response (if any)
 */
export function determineLedgerReportStatus({
  currentStatus,
  cacheResponse,
  partialReportResponse,
  fullReportResponse,
  errorMetadata,
}: DetermineLedgerReportStatusParams): LedgerReportStateResult {
  // Set up the default result
  const result: LedgerReportStateResult = {
    nextStatus: currentStatus,
    reportResponse: null,
  };
  // If we're already in the terminal state, maintain it, and return
  if (currentStatus === LedgerReportStatus.ALL_DATA_FETCHED_AND_CACHED) {
    return result;
  }

  // Check if there is a next status and, if so, apply it and end the current iteration of this effect.
  // Then, since the status has changed, the effect will run again with the new status.

  // A mapping of some statuses to their next status
  // e.g. we go from PARTIAL_DATA_FROM_NETWORK_REPORT_LOADED to FULL_DATA_FROM_NETWORK_LOADING
  const nextStatusDict = {
    [LedgerReportStatus.FULL_DATA_FROM_APOLLO_CACHE_REPORT_LOADED]:
      LedgerReportStatus.ALL_DATA_FETCHED_AND_CACHED,
    [LedgerReportStatus.FULL_DATA_FROM_NETWORK_REPORT_LOADED]:
      LedgerReportStatus.ALL_DATA_FETCHED_AND_CACHED,
    [LedgerReportStatus.PARTIAL_DATA_FROM_NETWORK_REPORT_LOADED]:
      LedgerReportStatus.FULL_DATA_FROM_NETWORK_LOADING,
  };

  // Given a status, return its next status if it's in the dictionary
  if (nextStatusDict[currentStatus]) {
    result.nextStatus = nextStatusDict[currentStatus];
    return result;
  }

  const wasPartialReportReceived = Boolean(partialReportResponse.data?.ledgerReport);
  const wasFullReportReceived = Boolean(fullReportResponse.data?.ledgerReport);

  // Handle status-specific transitions
  switch (currentStatus) {
    case LedgerReportStatus.FULL_DATA_FROM_APOLLO_CACHE_LOADING:
      if (cacheResponse.loading) break;

      // Handle loaded full data from the cache
      if (cacheResponse.data?.ledgerReport) {
        result.nextStatus = LedgerReportStatus.FULL_DATA_FROM_APOLLO_CACHE_REPORT_LOADED;
        result.reportResponse = { data: cacheResponse.data, error: undefined };
      } else {
        result.nextStatus = LedgerReportStatus.PARTIAL_DATA_FROM_NETWORK_LOADING;
      }
      // No need to handle an error here since we will move to the next status regardless
      break;

    case LedgerReportStatus.PARTIAL_DATA_FROM_NETWORK_LOADING:
      if (partialReportResponse.loading) break;

      // Handle loaded partial data from the network
      if (wasPartialReportReceived) {
        result.nextStatus = LedgerReportStatus.PARTIAL_DATA_FROM_NETWORK_REPORT_LOADED;
        result.reportResponse = { data: partialReportResponse.data || undefined, error: undefined };
      } else if (partialReportResponse.error) {
        reportError(`Error fetching partial FS report at status: ${currentStatus}`, {
          error: partialReportResponse.error,
          ...errorMetadata,
        });
      }
      break;

    case LedgerReportStatus.FULL_DATA_FROM_NETWORK_LOADING:
      if (fullReportResponse.loading) break;

      // Handle loaded full data from the network
      if (wasFullReportReceived) {
        result.nextStatus = LedgerReportStatus.FULL_DATA_FROM_NETWORK_REPORT_LOADED;
        result.reportResponse = { data: fullReportResponse.data || undefined, error: undefined };
      } else if (fullReportResponse.error) {
        reportError(`Error fetching full FS report at status: ${currentStatus}`, {
          error: fullReportResponse.error,
          ...errorMetadata,
        });
      }
      break;

    default:
      // Start with checking the cache if we're ready to fetch data (not ingesting or waiting)
      if (currentStatus !== LedgerReportStatus.INGESTING_OR_WAITING_ON_DATA) {
        result.nextStatus = LedgerReportStatus.FULL_DATA_FROM_APOLLO_CACHE_LOADING;
      }
  }

  return result;
}
