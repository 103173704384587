import { LedgerReportStatus } from "./types";

export const shouldShowSyncingUI = (status: LedgerReportStatus): boolean => {
  // Everything but ALL_DATA_FETCHED_AND_CACHED should be considered a syncing state
  return status !== LedgerReportStatus.ALL_DATA_FETCHED_AND_CACHED;
};

export const shouldShowLoadingUI = (status: LedgerReportStatus): boolean => {
  // Everything except the following should be considered a "loading" state:

  // FULL_DATA_FROM_NETWORK_LOADING
  // FULL_DATA_FROM_NETWORK_REPORT_LOADED
  // ALL_DATA_FETCHED_AND_CACHED

  // This is because we want to show the partial report while the full report data is being fetched
  return (
    status !== LedgerReportStatus.FULL_DATA_FROM_NETWORK_LOADING &&
    status !== LedgerReportStatus.FULL_DATA_FROM_NETWORK_REPORT_LOADED &&
    status !== LedgerReportStatus.ALL_DATA_FETCHED_AND_CACHED
  );
};
