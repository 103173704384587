import { ApolloError } from "@apollo/client";
import {
  DeltaColumns,
  EnhancedLedgerReportLine,
  LedgerReportLineMap,
} from "components/dashboard/Report/types";
import {
  AvailableClassesResult,
  DynamicReportType,
  LedgerReportFilterInput,
  LedgerView,
  ReportTimePeriod,
  LedgerReportQuery,
  LedgerReportColumnBy,
  IntervalMetadataFragment,
  LedgerReportLineFragment,
} from "graphql/types";

/**
 * This enum represents the states in our report fetching lifecycle which follows:
 * 1. Try Apollo cache first for instant loading
 * 2. If no cache, fetch partial data for faster summary view
 * 3. Finally, fetch full data for the complete report with all the breakout data
 */

export enum LedgerReportStatus {
  INGESTING_OR_WAITING_ON_DATA = "Ingesting your initial data",
  FULL_DATA_FROM_APOLLO_CACHE_LOADING = "Checking the cache for your data",
  FULL_DATA_FROM_APOLLO_CACHE_REPORT_LOADED = "Returning data from the cache",
  PARTIAL_DATA_FROM_NETWORK_LOADING = "Fetching your report",
  PARTIAL_DATA_FROM_NETWORK_REPORT_LOADED = "Report loaded",
  FULL_DATA_FROM_NETWORK_LOADING = "Loading revenue sources and vendors",
  FULL_DATA_FROM_NETWORK_REPORT_LOADED = "Revenue sources and vendors loaded",
  ALL_DATA_FETCHED_AND_CACHED = "Up to date",
}

export type ReportResponseType = {
  data: LedgerReportQuery | undefined;
  error?: ApolloError | undefined;
  loading?: boolean | undefined; // the loading state of the individual report returned by useLedgerReportQuery
};

export const initialReportResponse: ReportResponseType = {
  data: undefined,
  error: undefined,
  loading: false,
};

export type useFetchLedgerReportProps = {
  companyId: string;
  initialIngestCompleted: boolean;
  /**
   * Time periods represent arbitrary chunks of time to fetch for the report.
   * You can supply a friendly name and make columns of months, quarters, etc.
   */
  timePeriods: ReportTimePeriod[];
  type: DynamicReportType;
  view?: LedgerView;
  groupBy?: string;
  classifications?: AvailableClassesResult;
  filter?: LedgerReportFilterInput;
};

type BalanceByColumn = {
  columnKey: string;
};

type LedgerReportLine = {
  balanceByColumn?: BalanceByColumn[];
};

type LedgerReportBase = {
  lines: LedgerReportLine[];
  highWatermarkToken?: string;
  __typename?: string;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface LedgerReportByInterval extends LedgerReportBase {
  __typename: "LedgerReportByInterval";
  intervalMetadata: IntervalMetadataFragment[];
}

export type LedgerReport = LedgerReportBase | LedgerReportByInterval;

export type BuildReportResult = {
  columns: string[];
  hiddenColumns: DeltaColumns[];
  columnBy: LedgerReportColumnBy;
  type: DynamicReportType;
  data: LedgerReportLineMap;
  rootNodes: LedgerReportLineFragment[];
  loading: boolean;
  highWatermarkToken?: string | null | undefined;
  timePeriods?: IntervalMetadataFragment[] | undefined;
  enhancedRootNodes: EnhancedLedgerReportLine[];
  isInProgress: boolean;
  firstTimePeriod: ReportTimePeriod;
  lastTimePeriod: ReportTimePeriod;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface BuildReportResultWithStatus extends BuildReportResult {
  status?: LedgerReportStatus;
  refetch?: () => void;
}
