import React from "react";
import { Area, ComposedChart, Line, ResponsiveContainer, Tooltip } from "recharts";

import { Box } from "@puzzle/ve";
import { S, colors } from "@puzzle/theme";

import { GraphToolTip } from "./GraphToolTip";
import { GraphDateRange } from "./GraphDateRange";

export type DailyTotalCashValue = {
  date: string;
  totalCash: {
    amount: string;
    currency: string;
  };
};

export const DailyCashGraph = ({ dailyTotalCash }: { dailyTotalCash?: DailyTotalCashValue[] }) => {
  if (!dailyTotalCash || dailyTotalCash.length === 0) {
    return null; // shows nothing until the graph is ready
  }

  const startDate = dailyTotalCash[0]?.date;
  const endDate = dailyTotalCash[dailyTotalCash.length - 1]?.date;

  return (
    <Box css={{ width: "100%", height: "100%", paddingLeft: S.$1, paddingRight: S.$1 }}>
      <ResponsiveContainer width="100%" height="85%">
        <ComposedChart
          data={dailyTotalCash.map((x) => ({
            date: x.date,
            value: parseFloat(x.totalCash.amount),
          }))}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={colors.purple500} stopOpacity={1.0} />
              <stop offset="100%" stopColor={colors.purple900} stopOpacity={1.0} />
            </linearGradient>
          </defs>
          <Area type="monotone" dataKey="value" fill="url(#colorUv)" isAnimationActive={false} />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#B891E8"
            strokeWidth={3}
            dot={false}
            isAnimationActive={false}
          />

          <Tooltip
            wrapperStyle={{ outline: "none" }}
            cursor={false}
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                return (
                  <GraphToolTip
                    date={payload[0].payload.date}
                    value={payload[0].payload.value}
                    includeDate
                  />
                );
              }

              return null;
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <GraphDateRange
        startDate={startDate}
        endDate={endDate}
        css={{ paddingLeft: S.$1h, paddingRight: S.$1h, marginTop: "6px" }}
      />
    </Box>
  );
};
